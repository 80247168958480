import 'reflect-metadata';

import { plainToClass, ClassConstructor } from 'class-transformer';
import {
  validateSync,
  ValidationError,
  ValidatorOptions,
} from 'class-validator';

const defaultOptions = { whitelist: true };
export class ValidatedClass {
  from<T = Record<string, unknown>>(
    obj: T,
    options: ValidatorOptions = defaultOptions,
  ): this {
    Object.assign(
      this,
      plainToClass(this.constructor as ClassConstructor<this>, { ...obj }),
    );
    return this.validate(options);
  }

  private validate(validatorOptions: ValidatorOptions): this {
    const errors = validateSync(this, {
      ...validatorOptions,
      validationError: { target: false },
    });
    if (errors.length > 0) {
      throw new Error(
        `${this.constructor.name}: ${this.validationErrors(errors)}`,
      );
    }
    return this;
  }

  private validationErrors(errors: ValidationError[]): string {
    return errors
      .map((e) => {
        if (e.constraints) return Object.values(e.constraints).join(', ');
        return String(e);
      })
      .join(', ');
  }
}
